.services-images {
  display: flex;
  align-items: stretch;
  gap: 4%;
  width: 100%;
}

.services-images > img {
  width: 48%;
  height: 250px;
  object-fit: cover;
  display: block;
}
.services-images-btn {
  width: 100%;
}


.clients-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  align-items: center;
  justify-items: center;
  padding: 10px;
}

.clients-wrapper img {
transition: all 500ms ease;
}

.clients-wrapper img:hover {
  transform: scale(1.2);
}

.footer-top {
  padding: 40px 0;
}

@media (max-width: 668px) {
  .services-images {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .services-images > img {
    width: 100%;
    height: 200px;
  }
 
  .clients-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 468px) {
  .clients-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes anime {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fixed-link {
  color: white;

  background: #65118c;

  position: fixed;
  bottom: 25px;
  right: 50px;
  z-index: 50;

  
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: anime 1.2s infinite;
}

.fixed-link i {
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
  font-family: 'Montserrat', sans-serif !important;
}


.header-nav-link {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: fit-content;
  gap: 5px;



}

.header-nav-link::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #65118c;
  transition: all 400ms ease;
  max-width: 0;
}

.header-nav-link:hover::after {
  max-width: 100%;
}

.line-clamp {
  display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  	overflow: hidden;
}
.line-clam-2 {
  display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  	overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  	overflow: hidden;
}
.line-clamp-4 {
  display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  	overflow: hidden;
}


.hw-list {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 3%;
  row-gap: 30px;
  flex-wrap: wrap;
}
.hw-item {
  width: 30%;
  padding: 25px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.hw-item-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-top: calc(-50px);
}
.hw-item-desc {
  font-size: 15px;
  text-align: center;
}

@media (max-width: 1000px) {
  
  .hw-item {
    padding: 25px 10px;
  }
}

@media (max-width: 1024px) {
  .hw-list {
    column-gap: 6%;
  }
  .hw-item {
    width: 47%;
  }
}

@media (max-width: 500px) {
  .hw-list {
    flex-direction: column;
  }
  .hw-item {
    width: 100%;
  }
}

.footer-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;

}
.footer-section {
  width: 100%;
}
.footer-section-heading {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4;
  margin-bottom: 10px;

}
.footer-section-line {
  display: flex;
  align-items: center;
  gap: 5%;
  margin-bottom: 18px;
}

.footer-section-line::before {
  content: "";
  width: 10%;
  height: 2px;
  background-color: #65118c;
}


.footer-section-line::after {
  content: "";
  width: 85%;
  height: 2px;
  background-color: rgb(148 163 184);
}

.footer-section-bottom {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-section-text {
  font-size: 12px;
  line-height: 1.3;
  
}
.footer-social-links-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer-social-links-item {

}
.footer-social-link {
  width: 34px;
  height: 34px;
  background-color: #00000074;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-link img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.footer-bottom-link {
  display: flex;
  align-items: center;
  gap: 6px;
}

.footer-bottom-link img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

@media (max-width:768px) {
  .footer-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (max-width:468px) {
  .footer-wrapper {
    padding: 0 20px;
    grid-template-columns: 1fr;
  }
}